<template>
  <a-modal
      centered
      :width='700'
      title="批量推送数据"
      v-model="showModal"
      @ok='handleConfirm'
      @cancel='handleCancel'
  >
    <div>
      <a-form-model v-bind="layout" :model='forms' :rules='rules' ref="ruleForm">
        <a-form-model-item label="选择大币种">
          <a-select
              @search="searchBigCoinListData"
              v-model="forms.coinKindId"
              :filter-option="untils.filterOption"
              showSearch
              placeholder="请选择大币种"
              allowClear
              style="width:250px"
              @change="handleChangeBigCoin"
          >
            <a-select-option
                v-for="item of bigCoinListData"
                :key="item.id"
                :value="item.id"
            >{{ item.coinKindName }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='选择难度等级' prop='content'>
          <a-select
              v-model="forms.questionLevel"
              :filter-option="untils.filterOption"
              showSearch
              style="width:250px"
              placeholder="请选择"
          >
            <a-select-option
                v-for="(item, index) of questionLevelList"
                :key="index"
                :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="图片">
          <div class="images">
            <div class="images-title">
              <div>正</div>
              <div>反</div>
            </div>
            <div class="up-image">
              <div class="up-left">
                <a-upload
                    :key="imgKey"
                    :action="ip + '/file/upload'"
                    list-type="picture-card"
                    :file-list="coverList"
                    multiple
                    @preview="handlePreview"
                    @change="handleChangeCoverList"
                >
                  <div>
                    <a-icon type="plus" />
                    <div class="ant-upload-text">上传图片</div>
                  </div>
                </a-upload>
              </div>
            </div>
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import untils from "@/untils"
import {questionLevelList} from "@/views/cmsPage/versionManage/duibanQuestionList/_data"
import {mapState} from "vuex"
export default {
  computed: {
    ...mapState(['ip'])
  },
  data() {
    return {
      untils,
      bigCoinListData: [],
      showModal: false,
      questionLevelList,
      imgKey: Math.random() * 10 + '_smm',
      coverList: [],
      forms: {
        coinKindId: undefined,
        coinKindName: undefined,
        questionLevel: undefined,
        coverImages: []
      },
      rules: {},
      layout:{
        labelCol: { span: 4 },//标签占5份  共24份
        wrapperCol: { span:20 },
      },
      authParams: {
        userId: undefined,
        userName: undefined,
        coinId: undefined,
        pageNum: 1,
        pageSize: 10
      },
    }
  },
  methods: {
    show(data) {
      this.showModal = true
    },
    /** 改变选择大币种 */
    handleChangeBigCoin(e) {
      console.log(e, 'slslslslsslsl')
      if (e) {
        this.forms.coinKindName = this.bigCoinListData.find(el => el.id === e).coinKindName
      }
    },
    /** 提交 */
    async handleConfirm() {
      if (!this.forms.coinKindId) return this.$message.warning('请选择大币种')
      if (!this.forms.questionLevel) return this.$message.warning('请选择难度等级')
      if (this.coverList.length <= 0) return this.$message.warning('请上传图片')
      const pushImages = [];
      let count = -1;
      this.coverList.forEach((el, index) => {
        if (index % 2 == 0) {
          count++;
          pushImages[count] = [];
        }
        pushImages[count].push(el.url);
      })
      this.forms.coverImages = pushImages.map((el) => {
        return el.join(",");
      });
      const res = await this.axios.post('/dq_admin/duibanPushQuestion/manualUploadPushQuestion', this.forms)
      if (res.status !== '200') return
      this.$message.info( '上传成功')
      this.$emit('success')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.forms =  {
        coinKindId: undefined,
        coinKindName: undefined,
        questionLevel: undefined,
        coverImages: undefined
      }
      this.coverList = []
      this.showModal = false
    },
    /** 搜索大币种 */
    searchBigCoinListData(inner) {
      this.axios("/dq_admin/kind/list", {
        params: { coinName: inner },
      }).then((res) => {
        if (res.status == "200") {
          const { records } = res.data;
          this.bigCoinListData = records;
        }
      });
    },
    /** 显示大图 */
    handlePreview(file) {
      const picturesList = this.coverList.map((row) => {
        return row.url;
      });
      const curIndex = picturesList.findIndex(el=>{
        return el == file.url
      })
      if (picturesList && picturesList.length > 0) {
        this.$previewImg({
          list: picturesList.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),
          current: curIndex,
          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
    handleChangeCoverList({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.coverList = fileList;
    },
  }
}
</script>
<style scoped lang="scss">
.flex-start {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ml-10 {
  margin-left: 10px;
}
.images {
  width: 300px;
  margin-top: 10px;
  .images-title {
    width: 208px;
    display: flex;
    //margin-bottom: 10px;
    div {
      width: 104px;
      flex: 1;
      text-align: center;
      margin-right: 10px;
    }
  }
  .up-image {
    display: flex;
    .up-left {
      width: 350px;
      display: flex;
      flex-wrap: wrap;
    }
    .up-right {
      .right-list {
        width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
      .right-item {
        width: 150px;
        height: 104px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>