var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("a-input", {
            staticStyle: { width: "110px" },
            attrs: {
              allowClear: "",
              size: "small",
              placeholder: "问题Id关键字"
            },
            model: {
              value: _vm.params.keyword,
              callback: function($$v) {
                _vm.$set(_vm.params, "keyword", $$v)
              },
              expression: "params.keyword"
            }
          }),
          _c(
            "a-select",
            {
              staticStyle: { margin: "5px", width: "130px" },
              attrs: {
                dropdownMatchSelectWidth: false,
                "filter-option": _vm.untils.filterOption,
                dropdownMenuStyle: { "max-height": "350px" },
                allowClear: "",
                showSearch: "",
                size: "small",
                placeholder: "请选择大币种"
              },
              on: { search: _vm.searchData },
              model: {
                value: _vm.params.coinId,
                callback: function($$v) {
                  _vm.$set(_vm.params, "coinId", $$v)
                },
                expression: "params.coinId"
              }
            },
            _vm._l(_vm.bigCoinList, function(items) {
              return _c(
                "a-select-option",
                { key: items.id, attrs: { value: items.id } },
                [_vm._v(_vm._s(items.coinKindName))]
              )
            }),
            1
          ),
          _c(
            "a-select",
            {
              staticStyle: { margin: "5px", width: "100px" },
              attrs: {
                dropdownMatchSelectWidth: false,
                allowClear: "",
                "filter-option": _vm.untils.filterOption,
                size: "small",
                placeholder: "问题状态"
              },
              model: {
                value: _vm.params.questionStatus,
                callback: function($$v) {
                  _vm.$set(_vm.params, "questionStatus", $$v)
                },
                expression: "params.questionStatus"
              }
            },
            _vm._l(_vm.questionStatusList, function(item) {
              return _c(
                "a-select-option",
                { key: item.value, attrs: { value: item.value } },
                [_vm._v(_vm._s(item.name))]
              )
            }),
            1
          ),
          _c(
            "a-select",
            {
              staticStyle: { margin: "5px", width: "100px" },
              attrs: {
                dropdownMatchSelectWidth: false,
                allowClear: "",
                "filter-option": _vm.untils.filterOption,
                size: "small",
                placeholder: "回答状态"
              },
              model: {
                value: _vm.params.answerStatus,
                callback: function($$v) {
                  _vm.$set(_vm.params, "answerStatus", $$v)
                },
                expression: "params.answerStatus"
              }
            },
            _vm._l(_vm.answerStatusList, function(item) {
              return _c(
                "a-select-option",
                { key: item.value, attrs: { value: item.value } },
                [_vm._v(_vm._s(item.name))]
              )
            }),
            1
          ),
          _c(
            "a-select",
            {
              staticStyle: { margin: "5px", width: "150px" },
              attrs: {
                dropdownMatchSelectWidth: false,
                allowClear: "",
                "filter-option": _vm.untils.filterOption,
                size: "small",
                placeholder: "回答老师"
              },
              model: {
                value: _vm.params.targetUserId,
                callback: function($$v) {
                  _vm.$set(_vm.params, "targetUserId", $$v)
                },
                expression: "params.targetUserId"
              }
            },
            _vm._l(_vm.userAuthList, function(item) {
              return _c(
                "a-select-option",
                { key: item.userId, attrs: { value: item.userId } },
                [_vm._v(_vm._s(item.userName + "【" + item.userId + "】"))]
              )
            }),
            1
          ),
          _c(
            "a-select",
            {
              staticStyle: { margin: "5px", width: "110px" },
              attrs: {
                dropdownMatchSelectWidth: false,
                allowClear: "",
                "filter-option": _vm.untils.filterOption,
                size: "small",
                placeholder: "操作类型"
              },
              model: {
                value: _vm.params.operateStatus,
                callback: function($$v) {
                  _vm.$set(_vm.params, "operateStatus", $$v)
                },
                expression: "params.operateStatus"
              }
            },
            _vm._l(_vm.operateStatusList, function(item) {
              return _c(
                "a-select-option",
                { key: item.value, attrs: { value: item.value } },
                [_vm._v(_vm._s(item.name))]
              )
            }),
            1
          ),
          _c(
            "a-select",
            {
              staticStyle: { margin: "5px", width: "100px" },
              attrs: {
                dropdownMatchSelectWidth: false,
                allowClear: "",
                "filter-option": _vm.untils.filterOption,
                size: "small",
                placeholder: "难度等级"
              },
              model: {
                value: _vm.params.questionLevel,
                callback: function($$v) {
                  _vm.$set(_vm.params, "questionLevel", $$v)
                },
                expression: "params.questionLevel"
              }
            },
            _vm._l(_vm.levelList, function(item) {
              return _c(
                "a-select-option",
                { key: item.value, attrs: { value: item.value } },
                [_vm._v(_vm._s(item.name))]
              )
            }),
            1
          ),
          _c("a-range-picker", {
            staticStyle: { width: "200px", margin: "5px" },
            attrs: {
              placeholder: ["推送开始日期", "推送结束日期"],
              size: "small"
            },
            on: { change: _vm.handleChangePushQuestionTime }
          }),
          _c(
            "a-select",
            {
              staticClass: "m-5 w-100",
              attrs: {
                dropdownMatchSelectWidth: false,
                allowClear: "",
                "filter-option": _vm.untils.filterOption,
                size: "small",
                placeholder: "问题来源"
              },
              model: {
                value: _vm.params.sourceType,
                callback: function($$v) {
                  _vm.$set(_vm.params, "sourceType", $$v)
                },
                expression: "params.sourceType"
              }
            },
            _vm._l(_vm.sourceTypeTypeList, function(item) {
              return _c(
                "a-select-option",
                { key: item.value, attrs: { value: item.value } },
                [_vm._v(_vm._s(item.name))]
              )
            }),
            1
          ),
          _c(
            "a-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.search }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "div",
            { staticClass: "select-all-text" },
            [
              _c(
                "a-checkbox",
                {
                  attrs: { indeterminate: _vm.indeterminate },
                  on: { change: _vm.onCheckAllChange },
                  model: {
                    value: _vm.checkAll,
                    callback: function($$v) {
                      _vm.checkAll = $$v
                    },
                    expression: "checkAll"
                  }
                },
                [_vm._v(" 全选 ")]
              )
            ],
            1
          ),
          _c(
            "a-button",
            {
              staticClass: "header-button",
              attrs: {
                size: "small",
                type: "primary",
                disabled: _vm.isDisabled
              },
              on: {
                click: function($event) {
                  return _vm.handleActionBatchByExpertOrNormal()
                }
              }
            },
            [_vm._v("批量推送")]
          ),
          _c(
            "a-button",
            {
              staticClass: "header-button",
              attrs: { size: "small" },
              on: { click: _vm.handleCustomerUpLoadData }
            },
            [_vm._v("手动上传")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("QuestionPageTable", {
            attrs: {
              list: _vm.data,
              loading: _vm.loading,
              pagination: _vm.pagination
            },
            on: {
              changePage: _vm.handleChangePage,
              successStore: _vm.getList,
              selectChange: _vm.onSelectChange,
              resetVersion: function($event) {
                return _vm.handleResetVersion($event)
              }
            }
          })
        ],
        1
      ),
      _c("BatchPushDataPopup", {
        ref: "batchPushDataPopupEl",
        on: { addOrEditorSuccess: _vm.search }
      }),
      _c("CustomerUploadPopup", {
        ref: "customerUploadPopupEl",
        on: { success: _vm.search }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }