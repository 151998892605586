var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "selectedSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  { staticClass: "selected-box" },
                  [
                    _c("a-checkbox", {
                      staticClass: "checked",
                      model: {
                        value: row.checkStatus,
                        callback: function($$v) {
                          _vm.$set(row, "checkStatus", $$v)
                        },
                        expression: "row.checkStatus"
                      }
                    })
                  ],
                  1
                )
              ])
            }
          },
          {
            key: "questionId",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", [_vm._v(_vm._s(row.questionId))]),
                row.duibanId
                  ? _c(
                      "div",
                      {
                        staticClass: "link-text",
                        on: {
                          click: function($event) {
                            return _vm.handleGoToVersionList(row.duibanId)
                          }
                        }
                      },
                      [_vm._v("(" + _vm._s(row.duibanId) + ")")]
                    )
                  : _vm._e(),
                row.sameId
                  ? _c(
                      "div",
                      {
                        staticClass: "color-red cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.handleSameCurrencyList(row)
                          }
                        }
                      },
                      [_vm._v("（" + _vm._s(row.sameId) + "）")]
                    )
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemImage",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "item-image-list" }, [
                  _c("img", {
                    staticClass: "item-image",
                    attrs: { src: row.showImageUrl },
                    on: {
                      click: function($event) {
                        return _vm.handleShowBigImage(row, 0)
                      }
                    }
                  }),
                  _c("img", {
                    staticClass: "item-image",
                    attrs: { src: row.revealImageUrl },
                    on: {
                      click: function($event) {
                        return _vm.handleShowBigImage(row, 1)
                      }
                    }
                  })
                ]),
                row.duibanId
                  ? _c(
                      "div",
                      { staticClass: "mt-10" },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.handleResetVersion(row)
                              }
                            }
                          },
                          [_vm._v("重新对版")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            }
          },
          {
            key: "questionLevel",
            fn: function(row) {
              return _c("div", {}, [
                row.questionLevel === 2 || row.questionLevel == null
                  ? _c("span", { staticClass: "color-green" }, [_vm._v("简单")])
                  : _vm._e(),
                row.questionLevel === 4
                  ? _c("span", { staticClass: "color-red" }, [_vm._v("困难")])
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemVersionTop",
            fn: function(row) {
              return _c(
                "div",
                {},
                _vm._l(row.machineList, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "version-top",
                      class: item.bgColor
                    },
                    [
                      _vm._v(
                        " [" +
                          _vm._s(item.dikuVersionCount) +
                          "]" +
                          _vm._s(
                            item.itemName +
                              "·" +
                              item.versionName +
                              "（" +
                              item.distance +
                              "）"
                          ) +
                          " "
                      )
                    ]
                  )
                }),
                0
              )
            }
          },
          {
            key: "itemQuestionSourceTypeSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { class: _vm.sourceTypeClassType[row.sourceType] }, [
                  _vm._v(_vm._s(_vm.computedSourceTypeMapText(row.sourceType)))
                ])
              ])
            }
          },
          {
            key: "itemConfirmVersion",
            fn: function(row) {
              return _c("div", { staticClass: "color-green" }, [
                row.confirmItemName || row.confirmVersionName
                  ? _c("div", [
                      row.confirmItemName
                        ? _c("span", [_vm._v(_vm._s(row.confirmItemName))])
                        : _vm._e(),
                      row.confirmItemName
                        ? _c("span", [_vm._v("·")])
                        : _vm._e(),
                      row.confirmVersionName
                        ? _c("span", [_vm._v(_vm._s(row.confirmVersionName))])
                        : _vm._e()
                    ])
                  : _c("div", [_vm._v("-")])
              ])
            }
          },
          {
            key: "itemOperateStatus",
            fn: function(row) {
              return _c("div", {}, [
                row.operateStatus === 0 || row.operateStatus == null
                  ? _c("span", { staticClass: "color-red" }, [_vm._v("待处理")])
                  : _vm._e(),
                row.operateStatus === -1
                  ? _c("span", { staticClass: "color-orange" }, [
                      _vm._v("搁置")
                    ])
                  : _vm._e(),
                row.operateStatus === 10
                  ? _c("span", [_vm._v("已确认进样本库")])
                  : _vm._e(),
                row.operateStatus === 20
                  ? _c("span", [_vm._v("已确认进标库")])
                  : _vm._e(),
                row.operateStatus === 4
                  ? _c("span", { staticClass: "color-6699ff" }, [
                      _vm._v("假币")
                    ])
                  : _vm._e(),
                row.operateStatus === 6
                  ? _c("span", { staticClass: "color-6699ff" }, [
                      _vm._v("无法分辨")
                    ])
                  : _vm._e()
              ])
            }
          },
          {
            key: "resultStatusSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.answerStatus === 1
                  ? _c("span", { staticClass: "color-green" }, [
                      _vm._v("已回答")
                    ])
                  : _c("span", { staticClass: "color-red" }, [_vm._v("未回答")])
              ])
            }
          },
          {
            key: "pushStatusSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.questionStatus === 0
                  ? _c("span", { staticClass: "color-red" }, [_vm._v("未推送")])
                  : _vm._e(),
                row.questionStatus === 1
                  ? _c("span", { staticClass: "color-orange" }, [
                      _vm._v("已推送")
                    ])
                  : _vm._e()
              ])
            }
          },
          {
            key: "action",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  { staticClass: "action-btn" },
                  [
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "确定删除吗？",
                          "ok-text": "Yes",
                          "cancel-text": "No"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.handleAuctionBtn(row, "delete")
                          }
                        }
                      },
                      [
                        row.answerStatus !== 1
                          ? _c(
                              "a-button",
                              {
                                staticClass: "setup-btn mt-10",
                                attrs: { size: "small", type: "danger" }
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    row.answerStatus === 1
                      ? _c(
                          "a-button",
                          {
                            staticClass: "setup-btn mt-10",
                            attrs: {
                              size: "small",
                              type: "danger",
                              disabled: ""
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }