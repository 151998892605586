
/** 列表参数 */
export const questionTableListData = [
    { title: "选择数据", align: "center",scopedSlots: { customRender: "selectedSlot" }, width: 100 },
    { title: "问题ID", align: "center",scopedSlots: { customRender: "questionId" }, width: 150 },
    { title: "大币种", dataIndex: 'coinName'},
    { title: "图片", align: "center", scopedSlots: { customRender: "itemImage" }, width: 200 },
    { title: "难度等级", align: "center", scopedSlots: { customRender: "questionLevel" } },
    { title: "对版TOP5", align: "center", scopedSlots: { customRender: "itemVersionTop" }, width: 230 },
    { title: "问题来源", align: "center", scopedSlots: { customRender: "itemQuestionSourceTypeSlot" }, width: 120 },
    // { title: "确认版别", align: "center", scopedSlots: { customRender: "itemConfirmVersion" }, width: 80 },
    { title: "回答状态", align: "center",  scopedSlots: { customRender: "resultStatusSlot" }  },
    { title: "推送状态", align: "center",  scopedSlots: { customRender: "pushStatusSlot" }  },
    { title: "处理状态", align: "center",  scopedSlots: { customRender: "itemOperateStatus" }  },
    { title: "时间", dataIndex: 'createTime'},
    { title: "操作人", dataIndex: 'adminName'},
    { title: "操作", align: "center", scopedSlots: { customRender: "action" }},
]

/** 用户类型列表 */
export const userTypeList = [
    { value: 'expert', name: '专家用户' },
    { value: 'normal', name: '普通用户' },
]
/** 问题装填列表 */
export const questionStatusList = [
    { value: 0, name: '未推送' },
    { value: 1, name: '已推送' },
]

/** 回答状态 */
export const answerStatusList = [
    { value: 0, name: '未回答' },
    { value: 1, name: '已回答' }
]

/** 难度等级状态 */
export const questionLevelList = [
    { value: 2, name: '简单' },
    { value: 4, name: '困难' }
]