import instance from "@/axios"

/** 获取版别数据 */
export const getResetVersionTopApi = async (recordIds) => {
    return instance({
        url: '/dq_admin/duibanTask/batchResetDuibanRecord',
        method: 'GET',
        params: {
            recordIds: recordIds,
        }
    })
}